import { useState } from 'react';

const useDynamicModal = () => {
  const [visible, setVisible] = useState(false);
  const [modalProps, setModalProps] = useState(null);

  const showModal = modalProps => {
    setModalProps(modalProps);
    setVisible(true);
  };

  const hideModal = () => setVisible(false);

  return {
    visible,
    modalProps,
    showModal,
    hideModal
  };
};

export default useDynamicModal;
