import React from 'react';
import { Modal, Button } from '@retail-core/rds';
import useDynamicModal from './useDynamicModal';

export const RC_DYNAMIC_MODAL_SHOWN_IDS = 'RCDynamicModalShownIDs';

const DynamicModal = () => {
  const { visible, modalProps, showModal, hideModal } = useDynamicModal();

  window.RetailPortal.showModal = newModalProps => {
    showModal(newModalProps);
  };

  if (!visible || !modalProps) return null;

  const shownModalIDList = global.localStorage.getItem(RC_DYNAMIC_MODAL_SHOWN_IDS);

  const {
    title,
    content,
    okButtonText = 'OK',
    ID,
    displayFrequency,
    analyticsIdentifier
  } = modalProps;

  if (!title || !content) {
    // eslint-disable-next-line no-console
    console.error('modalProps missing title or content');
    return null;
  }

  const modalIsInShownList = () => {
    if (!shownModalIDList) {
      return false;
    }

    const shownModalIDsArr = shownModalIDList.split(',');
    return shownModalIDsArr.some(modalId => modalId === ID);
  };

  if (!ID) {
    return null;
  }

  if (displayFrequency === 'once' && modalIsInShownList()) {
    return null;
  }

  const newShownModalIDList = shownModalIDList ? `${shownModalIDList},${ID}` : ID;
  global.localStorage.setItem(RC_DYNAMIC_MODAL_SHOWN_IDS, newShownModalIDList);

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={() => hideModal()}
      data-analytics-retail-portal-dynamic-modal={`${analyticsIdentifier}_${ID}`}
      footer={
        // classname added so that it can be referenced by webdriver test and be closed so as to prevent test failure.
        <Button type="primary" className="dynamic-modal-close-btn" onClick={() => hideModal()}>
          {okButtonText}
        </Button>
      }
    >
      {content}
    </Modal>
  );
};

export default DynamicModal;
