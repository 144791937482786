import { getUserWithCache } from 'core/LegacySharedComponents/authorization';
import {
  getAdminAppList,
  getApplicationsWithDashboard,
  getPermittedApps
} from 'domain/Header/services';
import { detectActiveApplication } from '../LegacySharedComponents/utils';

export const getApplications = () =>
  getUserWithCache().then(user =>
    fetch(`${AUTH_SERVICE_URL}/applications`)
      .then(res => (res.status !== 200 ? null : res.json()))
      .then(applications => {
        const permittedApplications = getPermittedApps(applications, user);
        const applicationsWithDashboard = getApplicationsWithDashboard(permittedApplications, user);
        const currentApplication = detectActiveApplication(applicationsWithDashboard);
        const adminAppList = getAdminAppList(permittedApplications);

        const applicationsList =
          user?.user_type === 'SUPPLIER' ? permittedApplications : adminAppList;

        return {
          applications: applicationsList,
          currentApplication
        };
      })
  );
