import { SEARCH_PERMISSION } from 'domain/Header/constants';

export const convertObjectToString = (object, keyValueDelimiter = '; ', pairDelimiter = '=') =>
  Object.keys(object)
    .map(key => key + pairDelimiter + object[key])
    .join(keyValueDelimiter);

export const convertStringToObject = (string, keyValueDelimiter = '; ', pairDelimiter = '=') => {
  return string
    .split(keyValueDelimiter)
    .map(pair => pair.split(pairDelimiter))
    .reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value
      };
    }, {});
};

export const addDaysToDate = (date, days) => {
  const newDate = new Date(date);
  newDate.setTime(newDate.getTime() + days * 24 * 60 * 60 * 1000);
  return newDate;
};

export const detectActiveApplication = (applications, currentUrl = window.location.href) => {
  return applications.filter(app => {
    if (app.children) {
      return detectActiveApplication(app.children);
    }

    // because some apps have redirects to internal routes
    // application list will have: https://app.com & the app will redirect internally to https://app.com/foo
    // here, current URL will be "https://app.com/foo" but the application list will have "https://app.com" and it will fail equality check
    const currentURLInApp = app?.url?.indexOf?.(currentUrl) !== -1;
    const appInCurrentURL = currentUrl?.indexOf?.(app.url) !== -1;

    if (currentURLInApp) return currentURLInApp;

    if (appInCurrentURL) return appInCurrentURL;

    return currentUrl === app.url;
  })[0];
};

/**
 * Function to cache async function result based on arguments .
 * Will not call function if another call with the same arguments is already executing.
 * Function arguments should be easily serializable
 * @param fnToMemorize         - function to cache
 * @param timeToCache          - by default one minute
 * @param getCurrentTimeStamp
 * @returns {Function} function with built in cache
 */
export const memorize = (
  fnToMemorize,
  timeToCache = 6000, // 6 seconds
  getCurrentTimeStamp = () => Date.now()
) => {
  const cacheStorage = {};

  return (...args) => {
    const currentTimeStamp = getCurrentTimeStamp();
    const argsHash = `${args.length}|${args.join()}`;
    const { timestamp, lastResult } = cacheStorage[argsHash] || {};
    const isCacheValid = timestamp && currentTimeStamp - timestamp < timeToCache;

    if (isCacheValid) {
      return lastResult;
    }

    const newResult = fnToMemorize(...args);
    cacheStorage[argsHash] = {
      lastResult: newResult,
      timestamp: currentTimeStamp
    };

    return newResult;
  };
};

// used to update URL of the current history entry.
export const deleteParametersFromURL = (
  data = null,
  title = null,
  url = '#',
  history = window.history
) => {
  history.replaceState(data, title, url);
};

export const getIDPRequestURL = (Uri, endpoint) => {
  if (!Uri || typeof Uri === 'undefined') {
    return `${AUTH_SERVICE_URL}/api/${endpoint}`;
  }

  const queryParamHasOktaIDP = new URL(Uri).search.toLowerCase().includes('okta');
  let requestURL;
  if (queryParamHasOktaIDP) {
    requestURL = `${AUTH_SERVICE_URL}/api/${endpoint}?idp=okta`;
  } else {
    requestURL = `${AUTH_SERVICE_URL}/api/${endpoint}`;
  }

  return requestURL;
};

export const refreshTokenWorker = timeout => {
  let i = 0;
  function triggerMessage() {
    i += 1;
    postMessage(i);
  }
  setTimeout(() => {
    triggerMessage();
  }, timeout);
};

export const hasSearchPermission = user =>
  user?.permissions?.find(permission => permission.name === SEARCH_PERMISSION);
