/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { Skeleton } from '@retail-core/rds';
import { Environment, OnePortalHeader, Portal } from '@zdirect/one-portal-ui';
import DynamicModal from 'components/DynamicModal';
import { getApplicationsWithDashboard, getSupplierAppList } from 'domain/Header/services';
import { getUserWithCache, logout } from 'core/LegacySharedComponents/authorization';
import { UsersNameForm } from 'components/UsersNameForm/UsersNameForm';
import { hasSearchPermission } from 'core/LegacySharedComponents/utils';
import { pushToGA4Analytics, trackEvent } from 'domain/Header/tracking';
import Modal from 'components/Modal';
import Snackbar from 'components/Snackbar';
import { PaxThemeProvider } from '@partner-tech/pax-ui-kit/styles';
import PropTypes from 'prop-types';

const userNameNotSetIndicatorString = 'user name not set';

export const App = ({ userName }) => {
  const [applications, setApplications] = useState([]);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState();
  const [newUserNameAdded, setNewUserNameAdded] = useState(false);

  const isOnboardingSupplier =
    user?.permissions.length &&
    user.permissions.every(permission => permission.roleName === 'Supplier Onboarding External');

  // disable all the transitions and animations in Pax theme when reduce motion is enabled
  const theme = {
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @media (prefers-reduced-motion: reduce) {
            *: {
              transition: initial;
              animation: initial;
              transition-delay: 0s !important;
              transition-duration: 0s !important;
              animation-delay: -0.0001s !important;
              animation-duration: 0s !important;
              animation-play-state: paused !important;
            }
          }
        `
      }
    }
  };

  useEffect(() => {
    if (user) {
      window.RetailPortal.getApplications().then(({ applications, currentApplication }) => {
        const formattedApplications =
          user?.user_type === 'SUPPLIER'
            ? getSupplierAppList(applications, currentApplication)
            : applications;

        const applicationsWithDashboard = getApplicationsWithDashboard(formattedApplications, user);

        setApplications(applicationsWithDashboard);
      });
    }
  }, [user]);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    fetchCurrentUser();
    window.pushToGA4Analytics = pushToGA4Analytics;
  }, []);

  const fetchCurrentUser = async () => {
    try {
      setIsLoading(true);
      const user = await getUserWithCache();
      setUser(user);
      setIsLoading(false);
      window.zalandoEmployee = user?.user_type === 'ZALANDO_EMPLOYEE';
    } catch (e) {
      setIsLoading(false);
      window.RetailPortal.Sentry?.captureMessage?.(`User fetch failed: ${e}`);
      window.zalandoEmployee = undefined;
    }
  };

  if (isLoading) {
    return <Skeleton />;
  }

  const usersNameNotExist = userName?.toLowerCase() === userNameNotSetIndicatorString;

  function createApplicationsList(applications) {
    return applications.map(application => {
      if (application.children && !application.children.length) return null;

      if (application.children && application.children.length > 0) {
        return {
          id: application.name.toLowerCase(),
          label: application.name,
          children: createApplicationsList(application.children)
        };
      }

      return {
        id: application.name.toLowerCase(),
        label: application.name,
        url: application.url
      };
    });
  }

  const helpIconList = [
    {
      title: 'Help',
      href: HELP_URL,
      openInNewTab: true
    }
  ];
  const hideMenuOptions = usersNameNotExist;

  const displaySearchIcon = hasSearchPermission(user) && !hideMenuOptions;

  const getHeaderToBeRendered = () => {
    const userRoles = user?.permissions.map(permission => permission.roleName);
    const userIconSetting = {
      userProfile: {
        email: user?.email,
        name: user?.name,
        role: userRoles
      },
      dropdownOptions: [
        {
          iconName: 'AvatarAIcon',
          title: 'User profile',
          urlToMatch: `${AUTH_SERVICE_URL}/app/user-profile`,
          onOptionSelected: () => window.location.assign(`${AUTH_SERVICE_URL}/app/user-profile`)
        },
        {
          iconName: 'DoorOpenIcon',
          title: 'Sign out',
          onOptionSelected: () => logout()
        }
      ]
    };

    return user ? (
      <OnePortalHeader
        activePortal={Portal.RC}
        userGroups={user?.groups || []}
        environment={ENVIRONMENT === 'production' ? Environment.PRODUCTION : Environment.STAGING}
        navigation={{
          applications: createApplicationsList(applications).filter(app => app)
        }}
        helpIcon={{
          list: !isOnboardingSupplier ? helpIconList : []
        }}
        searchIcon={displaySearchIcon ? { href: SEARCH_APP_URL } : null}
        userIcon={!isOnboardingSupplier && !hideMenuOptions ? userIconSetting : null}
        onTrackEvent={trackingData => trackEvent(trackingData)}
        style={{
          paddingInline: 32,
          zIndex: '1000'
        }}
        theme={theme}
      />
    ) : null;
  };

  return (
    <>
      {getHeaderToBeRendered()}
      <PaxThemeProvider theme={theme}>
        <Snackbar />
        <Modal />
      </PaxThemeProvider>
      <DynamicModal />
      {!newUserNameAdded && usersNameNotExist && (
        <UsersNameForm setNewUserNameAdded={setNewUserNameAdded} />
      )}
    </>
  );
};

App.propTypes = {
  userName: PropTypes.string.isRequired
};

export default App;
