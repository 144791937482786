import React from 'react';
import { request } from '../ApiService/index';
import { Button, notification, Spin } from '@retail-core/rds';

let applications;

// Used for testing only
export const clearApplications = () => {
  applications = undefined;
};

const getApplications = async () => {
  if (applications) {
    return applications;
  }

  const host = ENVIRONMENT === 'production' ? 'retail' : 'retail-staging';

  try {
    const response = await request({ url: `https://register.${host}.zalando.com/applications` });
    applications = response.data;
  } catch (error) {
    throw new Error(`Could not load available applications: ${error}`);
  }

  return applications;
};

const detectApplicationName = async () => {
  const applications = await getApplications();
  if (window.location.host === 'localhost') {
    return 'local';
  }
  return applications.find(application => application?.url?.includes(window.location.hostname))
    ?.name;
};

const ERROR_THROTTLE_DELAY = 10000; // Only track errors every 10 seconds to prevent redundant errors

const shouldBeThrottled = () => {
  const lastError = window.localStorage.getItem('last_error');
  if (lastError && Number(lastError) + ERROR_THROTTLE_DELAY > new Date().getTime()) {
    return true;
  }
  window.localStorage.setItem('last_error', Date.now());
  return false;
};

/**
 * @param {Object} errorConfig
 * @param {string} errorConfig.errorId Some id which can be used to find the error in scalyr
 * @param {string} errorConfig.operation The operation the user performed and which throw the error
 * @param {string} errorConfig.message The error message shown to the user
 * @param {string} errorConfig.description A description shown in the notification for the user
 * @param {Error} errorConfig.rootCause The error object which was thrown
 * @param {Object?} errorConfig.metadata Additional metadata necessary to solve support cases like order or shipping notice id
 * @returns {Promise<{errorId: String; action: String; helpPageId: String}>}
 */
async function createErrorEvent(errorConfig) {
  const appName = await detectApplicationName();
  if (!appName) {
    throw new Error(`Errors won't be tracked for unknown host '${window.location.hostname}'`);
  }

  const k8sCluster = ENVIRONMENT === 'production' ? 'retail-operations' : 'retail-operations-test';

  try {
    return await request({
      url: `https://user-support.${k8sCluster}.zalan.do/error-events`,
      method: 'POST',
      data: {
        error_id: errorConfig.errorId,
        error_message: errorConfig.message,
        operation: errorConfig.operation,
        root_cause: errorConfig.rootCause,
        metadata: errorConfig.metadata ?? {},
        app: appName,
        url: window.location.href
      }
    });
  } catch (error) {
    throw new Error(`Creating error event failed: ${error}`);
  }
}

/**
 * @param {{id: number; action: String; helpPageId?: String}} errorEvent
 * @returns {Promise<void>}
 */
async function startConversation(errorEvent) {
  try {
    const k8sCluster =
      ENVIRONMENT === 'production' ? 'retail-operations' : 'retail-operations-test';
    const result = await request({
      url: `https://user-support.${k8sCluster}.zalan.do/error-events/${errorEvent.id}/conversations`,
      method: 'POST'
    });

    if (result.status < 400 && result.data.conversation_id) {
      window.Intercom?.('private:showConversation', result.data.conversation_id);
    }
  } catch (error) {
    throw new Error(`Creating conversation failed: ${error}`);
  }
}

/**
 * @param {Object} errorConfig
 * @param {string} errorConfig.errorId Some id which can be used to find the error in scalyr
 * @param {string} errorConfig.operation The operation the user performed and which throw the error
 * @param {string} errorConfig.message The error message shown to the user
 * @param {string} errorConfig.description A description shown in the notification for the user
 * @param {Error} errorConfig.rootCause The error object which was thrown
 * @param {Object?} errorConfig.metadata Additional metadata necessary to solve support cases like order or shipping notice id
 * @returns {Promise<void>}
 */
export async function notifyError(errorConfig) {
  if (shouldBeThrottled()) {
    return;
  }
  const notificationKey = performance.now().toString();

  notification.error({
    message: errorConfig.message,
    description: errorConfig.description,
    duration: 0,
    placement: 'topRight',
    bottom: 90,
    key: notificationKey,
    btn: <Spin spinning size="small" />
  });

  let action = <></>;
  let response;
  try {
    response = await createErrorEvent(errorConfig);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e.message);
  }

  if (response?.status < 400) {
    if (response.data.action === 'SHOW_HELP') {
      action = (
        <Button
          onClick={() => {
            window.Intercom?.('showArticle', response.data.help_page_id);
            notification.close(notificationKey);
          }}
        >
          Show help content
        </Button>
      );
    } else if (response.data.action === 'CREATE_CONVERSATION') {
      action = (
        <Button
          onClick={async () => {
            await startConversation(response.data);
            notification.close(notificationKey);
          }}
        >
          Ask for help
        </Button>
      );
    }
  }

  if (action) {
    notification.error({
      message: errorConfig.message,
      description: errorConfig.description,
      duration: 0,
      placement: 'topRight',
      bottom: 90,
      key: notificationKey,
      btn: action
    });
  }
}
