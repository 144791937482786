import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Form, Input, Typography, Row, Col, notification } from '@retail-core/rds';
import { tokenStorage } from 'core/LegacySharedComponents/authorization';
import React, { useState, useEffect } from 'react';
import UsersFormImg from './UsersFormImg.svg';
import PrivacyPolicy from 'components/PrivacyPolicyModal/PrivacyPolicy';
import PropTypes from 'prop-types';

const sucessMsg = {
  message: <p style={{ fontWeight: '700' }}>Your profile has been updated</p>,
  description:
    "Thanks for providing us with your data. You're now all set to start exploring the Retail Center!",
  duration: 0,
  icon: (
    <CheckCircleFilled
      style={{
        color: '#66676E'
      }}
    />
  )
};

export const UsersNameForm = props => {
  const [visible, setVisible] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'none';
    };
  }, []);

  const { setNewUserNameAdded } = props;

  const changeFirstName = e => {
    setFirstName(e.target.value);
  };
  const changeLastName = e => {
    setLastName(e.target.value);
  };

  const displayNotification = () => {
    notification.open(sucessMsg);
  };

  const showPrivacyPolicy = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const updateUsersName = async () => {
    const token = tokenStorage.getToken();
    const url = `${AUTH_SERVICE_URL}/api/supplier-user-details`;
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify({ first_name: firstName.trim(), last_name: lastName.trim() })
    };

    try {
      const response = await fetch(url, options);

      if (response?.status === 204) {
        displayNotification();
        setNewUserNameAdded(true);
      }
      setVisible(false);
      document.body.style.overflow = 'none';
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error in "/api/supplier-user-details" api ', err);
      setVisible(false);
      document.body.style.overflow = 'none';
    }
  };

  const disabledUpdate = !firstName.trim() || !lastName.trim();
  return (
    visible && (
      <div
        data-testid="usersname-form"
        style={{
          padding: '110px 20px',
          width: '100%',
          zIndex: '5',
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          backgroundColor: '#fff',
          height: '100vh',
          top: '0',
          left: '0',
          bottom: '0'
        }}
      >
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32
          }}
        >
          <Col span={12}>
            <Typography.Title>Hi, welcome on the Retail Center!</Typography.Title>
            <Typography.Text style={{ display: 'block', marginBottom: '24px' }}>
              We need some information to let you access the Retail Center.
            </Typography.Text>
            <Form layout="vertical">
              <Form.Item label="First name*">
                <Input
                  data-testid="fname"
                  placeholder="John"
                  value={firstName}
                  onChange={changeFirstName}
                />
              </Form.Item>
              <Form.Item label="Last name*">
                <Input
                  data-testid="lname"
                  placeholder="Doe"
                  value={lastName}
                  onChange={changeLastName}
                />
              </Form.Item>
            </Form>
            <Typography.Text style={{ marginTop: '24px' }}>
              Your data will remain protected, in accordance with our{' '}
              <strong style={{ cursor: 'pointer' }} onClick={showPrivacyPolicy} role="presentation">
                <u>Privacy Notice.</u>{' '}
              </strong>
            </Typography.Text>
            <Row>
              <Button
                type="primary"
                data-testid="usersname-form-close-btn"
                disabled={disabledUpdate}
                onClick={updateUsersName}
                style={{ marginTop: '24px' }}
              >
                Update Profile
              </Button>
            </Row>
          </Col>
          <Col span={10}>
            <UsersFormImg style={{ width: '270px', height: '290px', marginTop: '-29px' }} />
          </Col>
        </Row>
        <PrivacyPolicy isModalOpen={isModalOpen} handleCancel={handleCancel} />
      </div>
    )
  );
};

UsersNameForm.propTypes = {
  setNewUserNameAdded: PropTypes.func.isRequired
};
