import React from 'react';
import { Modal, Button } from '@retail-core/rds';
import PropTypes from 'prop-types';

const generalHeading = {
  marginBottom: '4px',
  fontSize: '1.25rem'
};
const secondLevelHeading = {
  marginTop: '32px',
  fontSize: '1.5rem',
  marginBottom: '4px'
};

const PrivacyPolicy = ({ isModalOpen, handleCancel }) => {
  const domain = 'retail.zalando.com';
  const createUrl = domain => `https://${domain}`;

  return (
    <Modal
      open={isModalOpen}
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="OK" onClick={handleCancel}>
          OK
        </Button>
      ]}
    >
      <div style={{ textAlign: 'center', lineHeight: '32px', marginBottom: '24px' }}>
        <h1>Data Protection Statement</h1>
        <h3>
          for <a href={createUrl(domain)}>{domain}</a>
        </h3>
      </div>
      <div>
        <p>
          Data protection is a matter of trust and your trust is important to us. So that you can
          feel secure when visiting our website {domain} (hereinafter referred to as the
          &ldquo;website&rdquo;) we observe the statutory regulations in processing your
          person-specific data and we would like to inform you here about our data acquisition and
          data utilization.
        </p>

        <h2 style={secondLevelHeading}>Abstract</h2>
        <p>The following data protection statement explains to you</p>

        <ul>
          <li>who is responsible for the data processing (see section 1.1 below)</li>
          <li>what your rights are with regard to the data processing (see section 1.2 below)</li>
          <li>for what purpose your data is processed and for how long (see section 1.3 below)</li>
          <li>how your data is processed (see sections 2 and 3 below)</li>
          <li>who else receives these data. (see sub-section 2.3 below)</li>
          <li>and what can change over the time (see section 4).</li>
        </ul>

        <h2 style={secondLevelHeading}>
          1. Responsible entity, rights of the data subjects and purpose and duration of data
          processing
        </h2>
        <p>
          1.1 The responsible entity for the acquisition, processing and utilization of your
          person-specific data, in the sense of the German Data Protection Law is Zalando SE
          (hereinafter referred to as Zalando).
        </p>

        <p>
          As the responsible entity, Zalando is available to you through the following channels:
        </p>

        <p>&ndash; by post under Zalando SE, Tamara-Danz-Str. 1, 10243 Berlin, Germany</p>
        <p>&ndash; by fax under +49 30 2759 46 93</p>
        <p>&ndash; by e-mail to our data protection officer at: datenschutz@zalando.de</p>

        <p>
          1.2 You have the following legal data protection rights under the relevant legal
          conditions including the EU General Data Protection Regulation (“GDPR”): Right to
          information (Article 15 GDPR), right to deletion (Article 17 GDPR), right to correction
          (Article 16 GDPR), right to restriction of processing (Article 18 GDPR), right to data
          portability (Article 20 GDPR), right to lodge a complaint with a supervisory authority
          (Article 77 GDPR), right to withdraw consent (Article 7 (3) GDPR) as well as the right to
          object to particular data processing measures (Article 21 GDPR).
        </p>
        <ul>
          <li>
            You can change most of your information yourself in your customer account. For other
            cases please contact customer service.
          </li>
          <li>
            The responsibilities of the data protection authorities depend on the seat of the
            competent authorities. You may, however, contact the data protection authority, which
            will then forward your complaint to the responsible authority. The authority responsible
            for Zalando is the Berlin Commissioner for Data Protection and Freedom of Information,
            Friedrichstr. 219 b, 10969 Berlin, Germany.
          </li>
          <li>
            If you have given consent for the processing of your data, you may withdraw it at any
            time. Withdrawal has no effect on the admissibility of the processing of your data which
            took place before the withdrawal. For further consequences of the withdrawal, please see
            section 2 and 3.
          </li>
          <li>
            You may object to the processing of your data for advertising purposes, including direct
            marketing (including in the form of data analysis) at any time without giving reasons.
          </li>
          <li>
            If we are processing your data on the basis of balancing of interests according to
            Article 6 (1) f GDPR (e.g. the reporting of creditworthiness to an external credit
            agency), you may object to the processing. When asserting your objection, we ask you to
            give the reasons why you do not wish us to continue processing your data. In the event
            of a justified objection, we will check the state of affairs and either stop or adjust
            the processing, or inform you of the urgent reasons worthy of protection why we are
            entitled to continue the processing.
          </li>
        </ul>

        <p>
          1.3 Purpose of the data processing as described hereinafter is the wholistic performance
          and usability testing of the website {domain} as a legitimate business interest, e.g.
          which buttons are clicked, how fast is the website loading and what is the visitor&rsquo;s
          technical environment to visit this website. The data will be stored and processed until
          fulfillment of this purpose. &nbsp;
        </p>
        <h2 style={secondLevelHeading}>2. Means of data processing</h2>
        <h3 style={generalHeading}>2.1 Cookies</h3>
        <p>
          The acceptance of cookies is not a prerequisite for access to our websites. We draw your
          attention, however, to the fact that our website and service has only limited
          functionality, if you do not allow us to place cookies.
        </p>

        <h4 style={generalHeading}>2.1.1 What are cookies?</h4>
        <p>
          Cookies are small files that are stored on your data carrier and hold certain settings and
          data for exchange with our system via your browser. Basically speaking there are 2
          different types of cookies: the so-called session cookies, that are deleted as soon as you
          close your browser; and temporary/permanent cookies, that are stored on your data carriers
          for a longer period or without limit. This type of storage helps us to design our websites
          and our offers for you and makes it easier for you to use them in that, for example,
          certain input from you can be stored so that you are not required to enter this
          repeatedly.
        </p>

        <h4 style={generalHeading}>2.1.2 Which cookies are used by Zalando?</h4>
        <p>
          Most of the cookies used by us are automatically deleted from your hard disk at the end of
          the browser session (hence the term &quot;session cookies&quot;). Session cookies are
          needed to make features available to you over several pages. In addition to these we also
          make use of cookies that remain on your hard disk. On a subsequent visit it is then
          automatically recognized that you have previously visited us, and we know which input and
          settings you prefer. These temporary or even permanent cookies (lifespan of 1 month to 10
          years) are stored on your hard disk and are automatically deleted after the pre-specified
          period. These cookies in particular serve to make our offer more user-friendly, more
          effective and more secure. Thanks to these files it is possible, for example, that the
          site shows you information that has been specially selected to match your interests. The
          sole purpose of these cookies is to adapt our offer to your wishes as well as possible and
          to make surfing with us as comfortable as possible for you.
        </p>

        <h4 style={generalHeading}>2.1.3 What data is stored in the cookies?</h4>
        <p>
          In the cookies used by Zalando, only pseudonymous data is stored. On activation of the
          cookie this is allocated an identification number; an allocation of your person-specific
          data to this identification number is not undertaken. Your name, your IP address and other
          such data that might allow the cookie to be traced to you directly are not placed in the
          cookie. On the basis of the cookie technology we receive only pseudonymized information,
          relating to which of our pages have been visited, etc.
        </p>

        <h4 style={generalHeading}>2.1.4 How can you prevent the storage of cookies?</h4>
        <p>
          In your browser you can adopt the setting that storage of cookies is only permissible when
          you agree to it, and thereby prevent the further storage of cookies in the future. If you
          want to accept only the Zalando cookies but not the cookies of our service companies and
          partners, you can select the browser setting &quot;Cookies von Drittanbietern
          blockieren&quot; [Block third-party cookies].As a rule, the help function in the menu bar
          of your web browser will show you how to reject new cookies and how to inactivate old
          ones.
        </p>

        <h3 style={generalHeading}>2.2. Log Files</h3>
        <p>
          With each access to the pages of Zalando usage data is transmitted by the respective
          Internet browser and is stored in record files, the so-called server log files. The
          records stored in this way contain the following data: date and time of the call-up, name
          of the page called up, IP address, referrer URL (URL of origin, from which you came to the
          websites), the quantities of data transferred, product information and the version of the
          browser used.
        </p>

        <p>
          These log file data records are evaluated by us, to help us further improve our offer and
          the Zalando shop, as well as to make these more-user-friendly, to detect and correct
          errors more quickly and to control server capacities. At the same time we use the log-file
          data records to defend our systems against attacks, e.g. in the context of so-called
          distributed-denial-of-service attacks, which are intended to block access to our Zalando
          shop by overloading it with enquiries.
        </p>

        <h3 style={generalHeading}>2.3. Web Analysis with Third Party Recipients</h3>
        <p>
          In order to constantly improve and optimize our offer, we make use of so-called tracking
          technologies. For this purpose we enlist the services of Google Inc. and Hotjar Ltd.
        </p>

        <h4 style={generalHeading}>2.3.1 Google Analytics</h4>
        <p>
          This website makes use of Google (Universal) Analytics, a web-analysis service from Google
          Inc. (www.google.com). Google (Universal) Analytics deploys methods that make it possible
          to analyse the use made of the website by you, e.g. the so-called &quot;cookies&quot;,
          which are text files that are stored on your computer. The information generated on your
          use of this website is transmitted, as a rule, to a Google server in the USA and stored
          there. By activating the IP-anonymize function on this website the IP address is
          shortened, within the member countries of the European Union or in other contracting
          states that were party to the agreement on the European Economic Area, before further
          transfer. Only in exceptional cases is the full IP address sent to a Google server in the
          USA and shortened there. The anonymized IP address transferred by your browser within the
          framework of Google Analytics is not brought together with other Google data.
        </p>

        <p>
          You can prevent the acquisition of the data generated by the cookie and relating to your
          use of the website (incl. your IP address) by Google, as well as its processing by Google,
          in that you download and install the browser plug-in available under the following link:{' '}
          <a href="http://tools.google.com/dlpage/gaoptout?hl=en">
            http://tools.google.com/dlpage/gaoptout?hl=en
          </a>
          . As an alternative to the browser plug-in you can click this link in order to prevent the
          recording by Google Analytics on this website in the future. In this case an opt-out
          cookie will be placed in your terminal device. If you delete your cookies you must then
          click the link again.
        </p>

        <h4 style={generalHeading}>2.3.2 Hotjar</h4>
        <p>
          We use Hotjar from Hotjar Ltd. in order to better understand our users&rsquo; needs and to
          optimize this service and experience. Hotjar is a technology service that helps us better
          understand our users experience (e.g. how much time they spend on which pages, which links
          they choose to click, what users do and don&rsquo;t like, etc.) and this enables us to
          build and maintain our service with user feedback. For the privacy policy of Hotjar,
          please visit: <a href="https://www.hotjar.com/privacy">https://www.hotjar.com/privacy</a>.
        </p>

        <p>
          Hotjar uses cookies and other technologies to collect data on our users&rsquo; behavior
          and their devices (in particular device&#39;s IP address (captured and stored only in
          anonymized form), device screen size, device type (unique device identifiers), browser
          information, geographic location (country only), preferred language used to display our
          website). Hotjar stores this information in a pseudonymized user profile. Neither Hotjar
          nor we will ever use this information to identify individual users or to match it with
          further data on an individual user. For further details, please see Hotjar&rsquo;s privacy
          policy by clicking on this link.
        </p>

        <p>
          You can opt-out to the creation of a user profile, Hotjar&rsquo;s storing of data about
          your usage of our site and Hotjar&rsquo;s use of tracking cookies on other websites by
          following this opt-out link.
        </p>

        <h2 style={secondLevelHeading}>3. Data Transfer Security</h2>
        <p>
          Your personal data is securely transferred by us with the help of coding. This naturally
          also applies to your orders, as well as to the customer login. Here we make use of the
          coding system TLS (Transport Layer Security) or SSL (Secure Socket Layer). With the help
          of both technical and organizational measures, moreover, we secure our websites and other
          systems against loss, destruction, access, alteration and distribution of your data by
          unauthorized persons.
        </p>
        <h2 style={secondLevelHeading}>4. Changes to this statement</h2>
        <p>
          In the course of improving our website and implementing new technologies onto our website,
          this data protection statement is subject to change. We recommend to visit this data
          protection statement at every visit to our website.
        </p>
      </div>
    </Modal>
  );
};
PrivacyPolicy.propTypes = {
  isModalOpen: PropTypes.bool,
  handleCancel: PropTypes.func
};

export default PrivacyPolicy;
