import React, { useEffect, useState } from 'react';
import { Card, Typography, Row, Col, Divider } from '@retail-core/rds';
import { getUserProfile } from './apiRequests';

export const AccountExpiredModal = () => {
  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await getUserProfile();
        setUserProfile(userProfile);
      } catch (e) {
        window.RetailPortal.Sentry?.captureMessage?.(`User Profile fetch failed: ${e}`);
      }
    };

    fetchUserProfile();
  }, []);

  if (!userProfile) {
    return null;
  }

  const getEmails = emails => (
    <ul>
      {emails.map(email => (
        <li key={email}>
          <Typography.Link
            className="retail-portal-account-expired-modal__admin-link"
            href={`mailto:${email}`}
          >
            {email}
          </Typography.Link>
        </li>
      ))}
    </ul>
  );

  const getAdministratorContactList = () => {
    const { user_roles_per_account: userRolesPerAccount } = userProfile;
    const adminEmails =
      userRolesPerAccount &&
      userRolesPerAccount.some(({ admin_emails: adminEmails }) => adminEmails.length);

    if (!adminEmails) {
      return (
        <p>
          We did not find any registered Retail Center administrator for your company. In order to
          extend your access, please reach out to your buying contact at Zalando and appoint an
          administrator.
        </p>
      );
    }

    return (
      <Row>
        To contact an administrator, reach out to:
        <Col>
          <ul>
            {userRolesPerAccount.map(
              ({ supplier_name: supplierName, admin_emails: emails }, index) => (
                <li key={supplierName + index}>
                  {supplierName}: {getEmails(emails)}
                </li>
              )
            )}
          </ul>
        </Col>
      </Row>
    );
  };

  return (
    <Card
      title="Your Retail Center account has expired"
      style={{
        width: '65%',
        margin: '100px auto 0'
      }}
    >
      <Typography.Paragraph>
        Your account has been temporarily disabled. All accounts must be renewed every 6 months for
        security reasons, to ensure only authorized individuals can access sensitive business data.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Should you wish to access the Retail Center, please follow these steps:
      </Typography.Paragraph>
      <ul>
        <li>
          <Typography.Text strong>
            If you have a Supplier Admin user role for all brands
          </Typography.Text>{' '}
          in your organization, please check your email inbox (and your spam folder) for an email
          with the following subject line: [YOUR ACTION IS REQUIRED] Your Zalando Retail Center
          account has expired. Follow the procedure to retrieve your access. Alternatively, you can
          contact another Supplier Admin* in your organization to reactivate your account.
        </li>
        <li>
          <Typography.Text strong>
            If you have a Supplier Admin role for only one of multiple brands in your organization
            OR another user role (Logistics, Order Manager, etc.)
          </Typography.Text>
          , please ask your Supplier Admin for all brands* to reactivate your account.
        </li>
      </ul>
      <Divider />
      <Typography.Paragraph>
        Your active Supplier Admin(s) for all brands is / are listed below:
      </Typography.Paragraph>
      {getAdministratorContactList()}
      <Typography.Paragraph>
        <Typography.Text strong>*</Typography.Text> Supplier Admins for all brands who can access
        their Retail Center account can reactivate any other account in their organization by{' '}
        <Typography.Link
          href="https://help.retail.zalando.com/en/articles/4248109-user-management-how-to-renew-an-expired-account"
          target="_blank"
          className="rds-link small"
        >
          following the procedure in the User Guide
        </Typography.Link>
        .
      </Typography.Paragraph>
      <Divider />
      <Typography.Paragraph>
        If you still have questions or you&apos;re unsure of your user role, please contact{' '}
        <Typography.Link href="mailto:supplier-support@zalando.de" className="rds-link small">
          supplier-support@zalando.de
        </Typography.Link>{' '}
        from the email address registered on the Retail Center and explain your situation.
      </Typography.Paragraph>
      <Typography.Paragraph>Your Zalando Team</Typography.Paragraph>
    </Card>
  );
};

export default AccountExpiredModal;
